<style>
.c-table-header {
  border-radius: 5px;
  border: 2px solid #7f8387;
  padding: 1rem;
}
.c-table-title {
  position: absolute;
  margin-top: -27px;
  margin-left: 1rem;
  z-index: 1;
  cursor: pointer;
}
.c-table-exfilters {
  transition: all 1s;
}
.c-table-spacer {
  margin-top: .5rem;
}
.c-table-st-clear {
  cursor: pointer;
}
.c-table-progress-overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-left: -1rem;
  margin-right: auto;
  margin-top: 3rem;
}
.c-table-progress-overlay.active {
  display: block;
}
@media (max-width: 1024px) {
  .c-table-sm-spacer {
    margin-top: .5rem;
  }
  .c-sm-spacer {
    margin-top: 1rem;
  }
}
</style>
<script>
import TimeAgo from 'vue2-timeago';
import VueSlideBar from "vue-slide-bar";
import { HalfCircleSpinner } from 'epic-spinners';
const humanizeDuration = require("humanize-duration");
import {get_acsrf_token, startsWith} from "@/methods";

import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import CopyableInput from "@/components/CopyableInput";
import {mapGetters} from "vuex";
import {Game} from "@/enums";

export default {
  props: ['server_id', 'options'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableInput,
    VueSlideBar,
    HalfCircleSpinner,
    // eslint-disable-next-line vue/no-unused-components
    TimeAgo
  },
  validations: {
    banForm: {
      identifier: {required, custom: true},
      reason: {required, minLength: minLength(3), maxLength: maxLength(40)},
      description: {maxLength: maxLength(400)}
    }
  },
  methods: {
    async fetchData() {
      if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.add('active');
      let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/queryPlayers`);
      url.searchParams.append('page', this.currentPage);
      url.searchParams.append('limit', this.perPage);
      url.searchParams.append('tz', Intl.DateTimeFormat().resolvedOptions().timeZone);
      url.searchParams.append('sort', JSON.stringify({
        key: this.sortBy,
        dir: this.sortDesc ? -1 : 1
      }));
      url.searchParams.append('query', JSON.stringify(this.query));
      let _queryInit = (new Date).getTime();
      this.items = await fetch(url, {credentials: 'include'})
          .then(res => {
            if(res.status === 429) {
              this.$toast.warning(this.$t('error.server.ratelimit.short'));
            }
            return res.json();
          })
          .then(data => {
            this.totalItems = parseInt(data.meta.total_items, 10);
            this.specialFields = data.special_fields;

            return data.entries;
          })
          .then(items => items)
          .catch(error => {
            this.query.delta = 0.0;
            this.$toast.error('Failed to contact query service');
            if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.remove('active');
          });

      this.query.delta = (new Date).getTime() - _queryInit;
      if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.remove('active');
    },
    currentDateTime() {
      let date = new Date();
      return `${date.getFullYear()}-${(date.getMonth()+1).pad()}-${date.getDate().pad()}T${date.getHours().pad()}:${date.getMinutes().pad()}`;
    },
    async clearSearchTerm() {
      this.query.search_term = null;
      await this.adhocSearch();
    },
    async adhocSearch() {
      await this.fetchData();
    },
    filtersToggle() {
      this.query.full = !this.query.full;
    },
    timeTrick(seconds) {
      return new Date(Date.now() - 1000 * seconds);
    },
    humanizeDuration(duration) {
      return humanizeDuration(duration * 1000);
    },
    supportsKillfeed() {
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
  },
  computed: {
    ...mapGetters([
      'getUILanguage',
      'getDTLocale'
    ]),
    positionQuery: {
      get() {
        return `${this.query.position.coordinates[0]}, ${this.query.position.coordinates[1]}`;
      },
      set(val) {
        let f = val.match('^(-?\\d+(\\.\\d+)?),\\s*(-?\\d+(\\.\\d+)?)$');
        if(f === null) return this.$refs.positionQueryInput.$el.classList.add('is-invalid');
        else this.$refs.positionQueryInput.$el.classList.remove('is-invalid');
        let x = f[1];
        let y = f[3];
        this.query.position.coordinates = [x, y];
      }
    },
    customFieldLabel() {
      return this.$t(`server.view.players.columns.custom.${this.query.customField}`);
    }
  },
  watch: {
    currentPage: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        });
      }
    },
    sortBy: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    },
    sortDesc: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    }
  },
  mounted() {
    this.fetchData().catch(error => {
      console.error(error)
    });
    this.fields.splice(3, 0, {
      key: 'custom',
      label: this.customFieldLabel,
      sortable: true
    });
  },
  created() {

  },
  data() {
    return {
      ready: false,
      error: false,
      specialFields: ['playtime', 'sessions'],
      items: [],
      fields: [
        {
          key: 'cftools_id',
          label: this.$t('server.view.players.columns.user'),
          sortable: true
        },
        {
          key: 'created_at',
          label: this.$t('server.view.players.columns.created'),
          sortable: true
        },
        {
          key: 'updated_at',
          label: this.$t('server.view.players.columns.updated'),
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('server.view.players.columns.actions'),
          sortable: true
        },
      ],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "date",
      sortDesc: true,

      // Query
      queryDateStart: false,
      query: {
        delta: 0.0,
        full: false,
        search_term: null,
        customField: 'playtime',
        date: {
          start: {
            enabled: false,
            value: this.currentDateTime()
          },
          end: {
            enabled: false,
            value: this.currentDateTime()
          }
        },
        position: {
          available: false,
          enabled: false,
          coordinates: [0.0, 0.0],
          radius: 100.0
        }
      }
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="c-table-header">
        <div class="c-table-title" v-on:click="filtersToggle()">
          <span class="badge badge-primary">
            <h6 class="mb-0 mt-0">
              {{ $t('components.table.filters.title') }}
              <small>
                {{ $t('components.table.filters.details') }}
              </small>
            </h6>

          </span>
        </div>
        <!-- Primary search term -->
        <div class="c-table-spacer"/> <!-- Spacer -->
        <div class="row">
          <div class="col-lg col-sm-12">
            <div class="mt-lg-2">
              {{$t('components.table.filters.search_term')}}
              <span class="badge badge-primary mb-1 c-table-st-clear" v-on:click="clearSearchTerm()">
                {{ $t('components.table.filters.clear') }}
              </span>
            </div>
            <b-form-input
                for="text"
                v-model="query.search_term"
                :placeholder="$t('components.table.filters.search_term')"
                v-on:keyup="$event.keyCode === 13 ? fetchData() : null"
            />
          </div>
        </div>
        <div class="c-table-exfilters" v-if="query.full">
          <!-- Date range -->
          <div class="c-table-spacer"/> <!-- Spacer -->
          <div class="row justify-content-center">
            <div class="col">
              <div class="row">
                <div class="col-lg-3 col-sm-12 text-lg-center">
                  <b-form-checkbox
                      switch
                      v-model="query.date.start.enabled"
                      class="mt-lg-2"
                  >
                    {{ $t('server.view.players.created.after') }}
                  </b-form-checkbox>
                </div>
                <div class="col-lg col-sm-12">
                  <div class="c-table-sm-spacer"/> <!-- Spacer -->
                  <b-form-input
                      id="date-time"
                      v-model="query.date.start.value"
                      type="datetime-local"
                  />
                </div>
              </div>
            </div>
            <div class="col">
              <div class="c-table-sm-spacer"/> <!-- Spacer -->
              <div class="row">
                <div class="col-lg-3 col-sm-12 text-lg-center">
                  <b-form-checkbox
                    switch
                    v-model="query.date.end.enabled"
                    class="mt-lg-2"
                  >
                    {{ $t('server.view.players.created.before') }}
                  </b-form-checkbox>
                </div>
                <div class="col-lg col-sm-12">
                  <div class="c-table-sm-spacer"/> <!-- Spacer -->
                  <b-form-input
                    id="date-time"
                    v-model="query.date.end.value"
                    type="datetime-local"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Special features, GEO etc. -->
          <template v-if="query.position.available">
          <div class="c-table-spacer"/> <!-- Spacer -->
          <div class="row justify-content-center">
            <div class="col">
              <div class="row">
                <div class="col-lg-3 col-sm-12 text-lg-center">
                  <b-form-checkbox
                      switch
                      v-model="query.position.enabled"
                      class="mt-lg-2"
                  >
                    {{ $t('components.table.filters.pos.coords') }}
                  </b-form-checkbox>
                </div>
                <div class="col-lg col-sm-12">
                  <div class="c-table-sm-spacer"/> <!-- Spacer -->
                  <div class="input-group">
                    <b-form-input
                        v-model="positionQuery"
                        ref="positionQueryInput"
                    />
                    <div class="invalid-feedback">
                      <span>
                        {{ $t('components.table.filters.pos.error') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-lg-3 col-sm-12 text-lg-center">
                  <div class="mt-lg-2">
                    {{ $t('components.table.filters.pos.radius') }}
                  </div>
                </div>
                <div class="col-lg col-sm-12">
                  <div class="c-table-sm-spacer"/> <!-- Spacer -->
                  <vue-slide-bar v-model="query.position.radius" :min="0" :max="1000" class="mt-n2" />
                </div>
              </div>
            </div>
          </div>
          </template>
        </div>
        <!-- Button -->
        <div class="c-table-spacer"/> <!-- Spacer -->
        <div class="row justify-content-center">
          <div class="col-lg-4 col-sm-12">
            <button class="btn btn-primary btn-block" v-on:click="adhocSearch()">
              <i class="fad fa-search"></i>
              {{ $t('components.table.filters.search') }}
            </button>
          </div>
        </div>
        <div class="float-right" v-if="query.delta > 0">
          <small>Query duration {{ query.delta }}ms</small>
        </div>
      </div>
      <div class="mt-2">
        <div class="c-table-progress-overlay" ref="queryLoadingIndicator">
          <div  class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
            </div>
          </div>
        </div>
        <b-table class="table-responsive-sm" show-empty :items="items" :fields="fields" :current-page="currentPage" :per-page="0" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
          <template #head(custom)="data">
            <div class="input-group input-group-sm">
              <b-form-select class="custom-select custom-select-sm" style="max-width: 9rem;" v-model="query.customField" v-on:change="adhocSearch">
                <option v-for="field in specialFields" :value="field" :key="field">
                  {{ $t(`server.view.players.columns.custom.${field}`) }}
                </option>
              </b-form-select>

              <div class="input-group-append">
                <label class="input-group-text">
                  {{ $t('server.view.players.custom') }}
                </label>
              </div>
            </div>
          </template>
          <template #empty="scope">
            <h4>{{ $t('components.table.filters.empty') }}</h4>
          </template>
          <template #cell(cftools_id)="data">
            <router-link :to="{name: 'profile', params: {cftools_id: data.item.user.cftools_id}}" target="_blank">
              {{ data.item.user.name }}
            </router-link>
          </template>
          <template #cell(actions)="data">
            <router-link :to="{name: 'profile', params: {cftools_id: data.item.user.cftools_id}}" target="_blank">
              <button class="btn btn-sm btn-primary">
                <i class="fad fa-info" />
                {{ $t('server.view.players.profile') }}
              </button>
            </router-link>
          </template>
          <template #cell(created_at)="data">
            {{ $d(parseDate(data.item.created_at), 'datetime', getDTLocale()) }}
          </template>
          <template #cell(updated_at)="data">
            {{ $d(parseDate(data.item.updated_at), 'datetime', getDTLocale()) }}
          </template>
          <template #cell(custom)="data">
            <template v-if="query.customField === 'playtime'">
              {{humanizeDuration(data.item.custom)}}
              <small class="ml-1 text-muted">
                ({{ data.item.omega.sessions }} {{ $t('server.view.players.sessions') }})
              </small>
            </template>
            <template v-else-if="['sessions', 'kills', 'suicides', 'deaths', 'longest_shot', 'longest_kill'].includes(query.customField)">
              {{ $n(data.item.custom) }}
              <template v-if="['longest_shot', 'longest_kill'].includes(query.customField)">
                m
              </template>
            </template>
            <template v-else-if="['kdratio'].includes(query.customField)">
              {{ $n(data.item.custom) }} ({{ $n(data.item.game.general.kills || 0) }} / {{ $n(data.item.game.general.deaths || 0) }})
            </template>
            <template v-else>
              <i>Error</i>
            </template>
          </template>
        </b-table>
      </div>
      <b-pagination class="float-right" size="md" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"></b-pagination>
    </div>
  </div>
</template>
